import { v4 as uuid } from 'uuid';

export const cvData = [
   {
      id: uuid(),
      img: {
         src: '/assets/images/israel_rails_logo.svg',
         alt: 'Israel Rails logo',
         width: 244,
         height: 34,
      },
      content: `From March 2022${'\n'}“Israel Railways”, Lod / Transport company${'\n'}TECH LEAD${'\u00A0'}${'\u2014'}${'\u00A0'}ReactJS / React Native Application Developer.`,
   },
   {
      id: uuid(),
      img: {
         src: '/assets/images/ABRA_logo.png',
         alt: 'ABRA logo',
         width: 156,
         height: 32,
      },
      content: `2021-2022${'\n'}“Abra” (Devalore in past), Petach Tikva / Technological company${'\n'}TEAM LEAD${'\u00A0'}${'\u2014'}${'\u00A0'}ReactJS / ElectronJS / React Native Application Developer.`,
   },
   {
      id: uuid(),
      img: {
         src: '/assets/images/isracart_logo.svg',
         alt: 'Isracart logo',
         width: 209,
         height: 43,
      },
      content: `2020-2021${'\n'}Isracard, Tel Aviv / Financial Company${'\n'}HEAD DEV TEAM MEMBER${'\u00A0'}${'\u2014'}${'\u00A0'}React Native Application Development (iOS/Android).`,
   },
   {
      id: uuid(),
      img: {
         src: '/assets/images/winner_logo.svg',
         alt: 'Winner logo',
         width: 172,
         height: 45,
      },
      content: `2019-2020${'\n'}Winner Israel, Petach Tikva / Analytics company${'\n'}APPLICATION DEVELOPER${'\u00A0'}${'\u2014'}${'\u00A0'}React Native Application Development (iOS/Android).`,
   },
   {
      id: uuid(),
      img: {
         src: '/assets/images/bbdo_logo.svg',
         alt: 'Gitam BBDO logo',
         width: 37,
         height: 86,
      },
      content: `Gitam BBDO, Ramat Gan / International Advertising Agency${'\n'}Brands Designer, Graphic Designer, UI/UX designer.`,
   },
];

export const sideBanners = [
   {
      id: uuid(),
      content: `MOBILE${'\n'}APPLICATIONS`,
   },
   {
      id: uuid(),
      content: `WEB${'\n'}APPLICATIONS`,
   },
   {
      id: uuid(),
      content: `REACT JS${'\n'}REACT NATIVE${'\n'}NODE JS`,
   },
];
